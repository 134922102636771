import React from 'react'
import styles from './Bio.module.css'
import { getGravatarURL } from '../utils/getGravatarURL'

interface BioProps {
  className?: string
}

function Bio(props: BioProps) {
  let photoURL = getGravatarURL({
    email: "h.viktorin@gmail.com",
    size: 56,
  })

  return (
    <div
      className={`
      ${styles.Bio}
      ${props.className || ''}
    `}>
      <img src={photoURL} alt="Me" />
      <p>
        A blog about the things i like by {' '}
        <a href="https://twitter.com/ageaenes/">Hans Wicktorin</a>.
        <br />
      </p>
    </div>
  )
}

export default Bio
