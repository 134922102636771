export default {
  // The blog's title as it appears in the layout header, and in the document
  // <title> tag.
  title: 'The blog',

  author: 'ageaenes',
  description:
    "Here you can read anything from this and that",

  // The number of posts to a page on the site index.
  indexPageSize: 10,
}
